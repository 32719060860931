/*custom-css*/
.card-description {
    max-width: 220px;
    white-space: nowrap;
    overflow-x: scroll;
}

.card-description::-webkit-scrollbar {
    display: none;
}

.rdt_TableHead {
    font-weight: bold !important;
}

/*.bank-type {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*    width: 150px;*/
/*    padding: 5px;*/
/*    height: 60px;*/
/*    position: relative;*/
/*}*/

.quick-amount {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.quick-amount > div {
    padding: 5px;
    width: 25%;
}


.btn-bank {
    cursor: pointer;
    height: 100%;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 10px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-bank.selected {
    border-color: #0a7ffb;

}

.btn-bank.selected::before {
    display: block;
    content: "\f00c";
    font-family: "Font Awesome 5 Free", sans-serif;
    position: absolute;
    top: 65px;
    right: 7px;
    font-size: 17px;
    z-index: 10;
    font-weight: 900;
    color: #fff;
    bottom: 0;
}

.btn-bank.selected::after {
    content: "";
    position: absolute;
    top: 59px;
    right: 0;
    border-color: #0162e8;
    border-style: solid;
    border-width: 17px;
    border-bottom-right-radius: 9px;
    border-top-left-radius: 10px;
}

.form-label {
    text-transform: uppercase;
}

@media only screen and (max-width: 576px) {
    .item-bank {
        width: 33.33%;
    }

}

@media only screen and (max-width: 1267px) {
    .quick-amount > div {
        width: 50%;
    }
}

.app-sidebar__user .user-pro-body img {
    border-radius: 50%;
    width: 70px;
}

#chart {
    margin-bottom: 0rem;
}

@media (max-width: 991.98px) {
    .navbar-expand .navbar-toggler {
        display: block !important;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse.collapse.show {
        display: block !important;
    }
}

.css-12e1lbo {
    z-index: 99999 !important;
}

.table thead th {
    font-size: 12px;
    white-space: nowrap;
}

.btn-view {
    padding: 4px 20px;
}

.profile.navtab-custom {
    padding: 11px 0 0px 0;
}

@media (max-width: 991px) {
    .main-header-center {
        display: none;
        margin: 0 0px;
    }
}

@media (max-width: 1090px) {
    .main-header-center {
        margin: 0 5px;
    }
}

@media (max-width: 992px) {
    .main-header-balance {
        display: block !important;
    }
}

.main-header-center .balance-content {
    padding: 2px;
    border: 1px solid #dde2ef;
    border-radius: 30px;
    width: 290px;
    display: grid;
    grid-template-columns: 20% 65% 15%;
    height: 46px;
    margin-right: 10px;
}

.main-header-center .balance-content .amount {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    font-size: 16px;
    color: #37374e;
    float: left;
}

.main-header-center .balance-content .amount div {
    font-weight: bold;
}

.main-header-center .balance-content .balance-transfer {
    float: right;
    display: flex;
    height: 100%;
    align-items: center;
    margin-right: 15px;
}

.main-header-center .balance-content .balance-transfer:hover {
    opacity: .75;
}

.main-header-center .balance-content .balance-transfer img {
    width: 20px;
    height: 20px;
}

.main-header-center .balance-content .amount small {
    font-size: 68%;
    color: #37374e;
    font-weight: 500;
    margin-top: 1px;
}

.main-header-center .balance-content .icon.rounded-circle-icon {
    display: inline-flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    background: rgba(21, 22, 23, 0.2);
    float: left;
}

.menu-balance-mobile {
    padding: 10px;
}

.menu-balance-mobile .balance-content {
    padding: 2px;
    border: 1px solid #adb5bd;
    border-radius: 30px;
    width: 100%;
    display: block;
    height: 46px;
    margin-right: 20px;
    margin-bottom: 10px;
}

.menu-balance-mobile .balance-content .balance-transfer {
    float: right;
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: 15px;
}

.menu-balance-mobile .balance-content .balance-transfer:hover {
    opacity: .75;
}

.menu-balance-mobile .balance-content .balance-transfer img {
    width: 20px;
    height: 20px;
}

.menu-balance-mobile .balance-content .amount {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    font-size: 17px;
    color: #37374e;
    float: left;
}

.menu-balance-mobile .balance-content .amount small {
    font-size: 68%;
    color: #37374e;
    font-weight: 500;
}

.menu-balance-mobile .balance-content .icon.rounded-circle-icon {
    display: inline-flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    text-align: center;
    background: rgba(21, 22, 23, 0.2);
    float: left;
}

.main-sidemenu .user-pro-body .left-icon {
    border: 3px solid #eae8f1;
    box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.3);
    padding: 3px;
    background: #fff;
}

.main-img-user .avatar-profile {
    width: 100px !important;
    height: 100px !important;
    border-radius: 60px !important;
}

.main-header-profile .main-img-user .avatar {
    width: 45px !important;
    height: 45px !important;
    border-radius: 60px !important;
}

.main-profile-menu .profile-user .avatar {
    width: 38px !important;
    height: 38px !important;
}

.table-bordered > :not(caption) > * {
    border-bottom: 1px solid #dde2ef;
}

@media (max-width: 767px) {
    .tb-paging {
        display: flex;
        flex-direction: row;
    }

    .tb-paging .btn {
        padding: 9px 12px;
    }
}


@media (min-width: 1331px) {
    .container-fluid.main-container .scoll-main-container {
        width: 1050px;
    }
}

@media (max-width: 1470px) {
    .container-fluid.main-container .scoll-main-container {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .container-fluid.main-container .scoll-main-container::-webkit-scrollbar {
        display: none;
    }

    .container-fluid.main-container .scoll-main-container .main-balance {
        width: 930px;
    }
}

.table-bordered thead th, .table-bordered thead td {
    background-color: #0546a1f2;
    color: #fff;
}

.cell-table-custom {
    display: grid;
    grid-template-columns: auto min-content;
    align-items: center;
    border: 1px solid #017e84;
    border-radius: 0.25rem !important;
}

.cell-table-custom span {
    display: inline;
    max-width: 100%;
    white-space: nowrap;
    vertical-align: top;
    font-size: 13px;
    margin: 0 10px;
}

.btn.btn-copy {
    background-color: #017e84;
    padding: 0.1rem 0.5rem;
    border-radius: 0 0.1rem 0.1rem 0;
}

.btn.btn-copy:hover, .btn.btn-copy:active {
    color: #FFF;
    background-color: #016b70;
    border-color: #01656a;
}

.btn.btn-copy .cell-icon-copy {
    font-size: 12px;
    color: #fff;
}

.left-profile .card .card-body {
    padding: .7rem 1rem;
}

.left-profile .card .card-body .counter-status {
    align-items: center;
    justify-content: space-between;
}

.left-profile .card .card-body .counter-status h5 {
    text-align: right;
    color: #4d5875;
    font-weight: 400;
}

.left-profile .card .card-body .counter-status h2 {
    text-align: right;
}

.left-profile .counter-icon {
    padding: 1rem 1rem;
}

.quote-badge {
    background-color: rgba(21, 22, 23, 0.2);
    color: #0162e8;
}

.badge {
    border-radius: 10px;
    min-width: 30px;
}

.row-number {
    width: 3%;
}

.cell-amount, .cell-amount1, .cell-amount2, .cell-amount3, .cell-amount4,
.cell-amount5, .cell-amount6, .cell-amount7, .cell-amount8, .cell-amount_included_fee, .cell-trans_amount, .cell-fee_amount, .cell-remaining_amount, .cell-fundout_fee {
    text-align: right;
}

.cell-Amount, .cell-Fee {
    text-align: right;
}

.cell-table-custom span {
    font-weight: 500;
}

.row-sum {
    background-color: #ffa50091 !important;
}

.btn.btn-search, .btn.btn-reload {
    color: #FFF;
    background-color: #016b70;
    width: 23.4%;
    margin: 2px;
}

.btn.btn-search:active, .btn.btn-search:hover, .btn.btn-reload:active, .btn.btn-reload:hover {
    color: #FFF;
    background-color: #026167;
}

.form-filter-date .btn.btn-search, .form-filter-date .btn.btn-reload {
    padding: 3px 20px;
}

.form-filter-date .btn.btn-search i {
    font-size: 18px;
    margin-top: 3px;
}

.form-filter-date .btn.btn-reload i {
    font-size: 19px;
    margin-top: 3px;
}

.form-filter-date input {
    margin-bottom: 10px;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .form-input-date {
        flex-wrap: wrap;
    }

    .btn.btn-search, .btn.btn-reload {
        width: 32%;
        margin: 2px;
    }

    .button-content span {
        width: 31% !important;
        margin: 2px;
    }
}

@media (min-width: 992px) {
    .form-filter-date {
        width: 600px !important;
    }
}

.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: inherit;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4d5875;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e1e5ef;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-datepicker__input-container input:focus-visible {
    outline: unset;
}

.react-datepicker__input-container input:focus {
    color: #4d5875;
    background-color: #fff;
    border-color: #b3c6ff;
    outline: 0;
    box-shadow: none;
}

.react-datepicker-wrapper {
    width: 90% !important;
}

.react-datepicker-wrapper .react-datepicker__input-container {
    width: 97%;
}

@media (max-width: 768px) {
    .react-datepicker-wrapper {
        width: 100% !important;
    }

    .react-datepicker-wrapper .react-datepicker__input-container {
        width: 100%;
    }
}

.react-datepicker-wrapper .react-datepicker__input-container svg {
    width: 33px;
    height: 33px;
}

.react-datepicker__view-calendar-icon input {
    padding: 6px 11px 5px 33px !important;
}

.title-message {
    font-weight: bold;
    text-transform: uppercase;
}

.body-message {
    min-height: 80px;
    background-color: #c0c6d07d;
    padding: 0.5rem;
    border-radius: 10px;
}

.footer-message {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    margin-top: 2px;
}

.body-popup-message {
    padding: .5rem !important;
}

.header-popup-message {
    padding: .6rem 1rem;
}

.settlement-tab .title-text {
    box-shadow: 0 1px 0 #eae8f1;
}

.settlement-tab .toggle-ios {
    display: none;
}

.settlement-tab .toggle-button {
    margin-bottom: 3px;
    margin-left: 5px;
}

.settlement-tab .toggle-ios + .toggle-btn {
    outline: 0;
    display: block;
    width: 35px;
    margin: 0 auto;
    height: 20px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #ccc;
    border-radius: 2em;
    padding: 2px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border: 1px solid #ccc;
}

.settlement-tab .toggle-ios + .toggle-btn:after {
    position: relative;
    display: block;
    content: "";
    width: 45%;
    height: 100%;
    left: 0;
    border-radius: 50%;
    background: #fbfbfb;
    -webkit-transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}

.settlement-tab .toggle-ios:checked + .toggle-btn {
    background-color: #22c03c;
}

.settlement-tab .toggle-ios:checked + .toggle-btn:after {
    left: 50%;
}

.button-content span {
    color: #FFF;
    background-color: #016b70;
    width: 23.4%;
    border: 1px solid transparent;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    font-size: 0.875rem;
    cursor: pointer;
    line-height: 1.5;
    padding: 3px 20px;
    margin: 2px;
}

.button-content span i {
    font-size: 19px;
    margin-top: 3px;
}

.form-control.filter-search {
    width: 100%;
}

@media (max-width: 768px) {
    .form-control.filter-search {
        width: 100%;
    }
}

.form-group.form-group-select, .form-group.form-group-input {
    position: relative;
    width: 100%;
}

.form-group.form-group-select i, .form-group.form-group-input i {
    position: absolute;
    font-size: 20px;
    top: 7px;
    left: 7px;
}

.form-group.form-group-select select, .form-group.form-group-input input {
    padding: 6px 11px 5px 33px !important;
}

@media (max-width: 767px) {
    .was-validated .form-control:invalid, .form-control.is-invalid, input.form-control.parsley-error, textarea.form-control.parsley-error {
        padding-right: 8px !important;
        background-image: none !important;
    }

    .was-validated .form-control:valid, .form-control.is-valid, input.form-control.parsley-success, textarea.form-control.parsley-success {
        padding-right: 8px !important;
        background-image: none !important;
    }

}

@media (min-width: 768px) {
    .was-validated .form-control:invalid, .form-control.is-invalid, input.form-control.parsley-error, textarea.form-control.parsley-error {
        padding-right: 15px !important;
        background-image: none !important;
    }

    .was-validated .form-control:valid, .form-control.is-valid, input.form-control.parsley-success, textarea.form-control.parsley-success {
        padding-right: 15px !important;
        background-image: none !important;
    }

}

.fundout-history-table {
    .cell-9, .cell-10 {
        text-align: right;
    }
}
