//Header part start********
.main-header-message,
.main-header-notification,
.main-profile-menu {
  .dropdown-toggle::after {
    display: none !important;
  }
}

.navbar-nav-right {
  .country-flag1::after {
    display: none !important;
  }

  .flag_content {
    display: block !important;
    margin: auto 3px;
  }
}

.main-header-message {
  .dropdown-menu.show {
    transform: translate(-260px, 65px) !important;
  }
}

.main-header-notification {
  .dropdown-menu.show {
    transform: translate(-260px, 65px) !important;
  }
}

.main-profile-menu {
  .dropdown-menu.show {
    transform: translate(-195px, 75px) !important;
  }
}

.flag_content {
  .dropdown-menu.show {
    transform: translate(11px, 65px) !important;
  }
}

.notify-status {
  left: 55px !important;
  top: 57px !important;
}

.Friend-status {
  left: 50px !important;
  top: 44px !important;
}

.horizontal .hor-scroll {
  position: static !important;

  > div {
    position: static !important;
  }
}

.nav .nav-item .dropdown-menu {
  top: -15px !important;
  left: 4px;
}

.search-fix {
  inset-inline-end: 0;
  width: 18%;
  position: absolute;
  inset-inline-start: 16.5%;
  inset-block-start: 80%;
  box-shadow: none;
}

//Header part end***********


//rightsidebar Start***********
.sidebar-right .tabs-menu-body .nav.nav-pills {
  padding: 20px !important;
}

.sidebar .tabs-menu .nav-pills .nav-item a {
  padding: 8px 16px;
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid #ebeaf1;
  font-weight: 300;
  background: #ECF0FAFF;
  color: #000;
}

.sidebar .tabs-menu .nav-pills .nav-item {
  width: 100%;
  display: block;
}

.sidebar .tabs-menu .nav-pills .nav-item .active {
  background: #0162E8FF !important;
  color: #fff !important;
  border: 1px solid #0162E8FF !important;
}

.modal-content {
  .dropdown-toggle::after {
    display: none !important;
  }
}

#audiomodal {
  .modal-content {
    background-color: #FFFFFFFF !important;
  }
}

#videomodal {
  .modal-content {
    background-color: #343A40FF !important;
  }
}

//rightsidebar end**********


.sales-bar {
  .apexcharts-legend-series {
    margin: 2px 28px 2px 5px !important;
  }
}

// .recent_order {
//     margin-bottom: 70px !important;
// }

.rsm-svg {
  width: 62%;
  transform: scale(1.25);
}

// Index page End********

//Accordion START**********
.accordion-dark {
  .accordion-button:not(.collapsed) {
    box-shadow: none;
    background: #ECF0FAFF !important;

  }

  .accordion-header button {
    display: block;
    padding: 14px 20px;
    position: relative;
    color: #242f48 !important;
    font-weight: 500;
    font-size: 14px;
    background-color: #ECF0FAFF;
  }

  .accordion-header button {
    background-color: #ECF0FAFF;
  }

  .accordion-header button.collapsed {
    color: #FFFFFFFF !important;
    background-color: #ECF0FAFF !important;
  }

  .accordion-body {
    background-color: #ECF0FAFF;
  }

}

.Select-sm__control {
  border: 1px solid #EAE8F1FF !important;
}

.Select-sm__control.Select-sm__control--is-disabled {
  background-color: #EAE8F1FF !important;
}

.rmsc .dropdown-container[aria-disabled="true"] {
  background-color: #EAE8F1FF !important;
}

.dark-theme {
  .Select-sm__control {
    //background-color: $dark-theme  !important;
    color: #FFFFFFFF !important;
    border: 1px solid #EAEDF133 !important;
  }

  .Select-sm__control.Select-sm__control--is-disabled {
    background-color: #FFFFFF33 !important;
  }

  .Select-sm__menu {
    background-color: #1F2940FF !important;
    color: #FFFFFFFF !important;
  }

  .Select-sm__input-container,
  .Select-sm__single-value {
    color: #fff !important;
  }

  .Select__multi-value {
    background-color: #FFFFFF33 !important;
  }

  .Select__multi-value__label {
    color: #fff !important;
  }

  .Select2__multi-value {
    background-color: #FFFFFF33 !important;
  }

  .Select2__multi-value__label {
    color: #fff !important;
  }

  .rodal-dialog {
    color: #FFFFFFFF !important;
    background: #1F2940FF !important;
  }

  .rmsc .gray {
    color: #1F2940FF !important;
  }

}

//ReactSelect2 END

@media (min-width: 991.95px) {
  .horizontal {
    .jumps-prevent {
      padding-top: 0px !important;
    }
  }
}

.swal2-styled.swal2-confirm {
  background-color: var(--primary-bg-color) !important;
  border: 1px solid #EAE8F1FF !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.dark-theme {
  .swal2-popup {
    background-color: #1F2940FF !important;
    color: #FFFFFFFF !important;
  }

  .swal2-styled.swal2-confirm {
    //background-color: $primary  !important;
    border: 1px solid #EAEDF133 !important;
  }
}

.rodal-close {
  display: none;
}


//Modal END*********

//Profile Css START*******
.profile.navtab-custom .nav-item a {
  padding: 12px 8px;
}

.panel-tabs {
  border-bottom: 1px solid #EAE8F1FF !important;
}

hr:not([size]) {
  height: 1px;
}

.option-dots.dropdown-toggle::after {
  display: none;
}

//Profile Css END*******


//Button Css START*****

.btn-block.dropdown-toggle::after {
  display: none;
}

//Button Css END*****


//Pagination Css START*****
.example .pagination li span {
  margin-right: 4px;
  border-radius: 4px;
}

//Pagination Css END*****

//Progress Bar Css START*******
.h-8.progress .progress-bar {
  height: 16px !important;
}

//Progress Bar Css END*******
//Tag Css START*******
.rti--tag {
  background-color: #007BFFFF !important;
  color: #FFFFFFFF !important;
  padding: 7px 10px !important;

  button {
    border-radius: 50% !important;
    background-color: transparent !important;
    color: #FFFFFFFF !important;
  }
}

//Tag Css END*******

//Navigation Css START*******
.nav-tabs .nav-item .nav-link {
  background-color: rgba(255, 255, 255, 0.5);
  border-width: 0;
  border-radius: 0;
  padding: 10px 15px;
  line-height: 1.428;
  color: #4d5875;
  margin-left: 3px;
}

.nav-tabs .nav-item .nav-link.active {
  color: #4d5875;
  background-color: #fff;
  border-color: #fff;
}

//Navigation Css END*******

//Tab Css START*******
.tabs-style-2 .main-nav-line .nav-link.active {
  box-shadow: inset 0 3px 1px var(--primary-bg-color) !important;
}

.tabs-menu {
  .panel-tabs {
    .nav-item {
      .nav-link {
        .active {
          background: var(--primary-bg-color) !important;
          color: #fff !important;
        }

        padding: 10px 24px 10px 24px;
        background: #ECF0FAFF;
        border-radius: 20px;
        margin: 0 5px 5px 0;
        text-align: center;
        display: block;
        color: #031b4e;
      }
    }
  }
}

.tabs-menu {
  .panel-tabs {
    .nav-item {
      .nav-link.active {
        background: var(--primary-bg-color) !important;
        color: #fff !important;
      }
    }
  }
}

.tabs-style-4 .nav.panel-tabs .nav-item a {
  padding: 10px 18px 10px 18px;
  background: #ECF0FAFF;
  border-radius: 4px;
  margin: 0 0 16px 0;
  text-align: center;
  display: block;
  color: #031b4e;
  width: 100%;
}

.tabs-style-4 .nav.panel-tabs .nav-item {
  display: block;
  width: 100%;
}

//Tab Css END*******

//Form Layout CSS START*******

.vertical.dropdown-toggle::after {
  display: none;
}

//Form Layout CSS END*******

//Todo-task css START*******
.no-caret {
  &.dropdown-toggle::after {
    display: none;
  }
}

//Todo-task css END*******

//Mail Css Start*******

.main-mail-menu .main-nav-column .nav-item .nav-link {
  border-top: 1px dotted #b9c2d8 !important;
}

.main-mail-menu .main-nav-column .nav-item:first-child .nav-link {
  border-top: 0 !important;
}

//ChatCss

.rounded-5 {
  border-radius: 5px !important;
}

//Mail Css END*******

//shop Css Start*******
.product-pagination.pagination li span {
  margin-right: 4px;
  border-radius: 4px;
}

//shop Css End*******


//Checkout Ecommerce Css Start*******
.wd25p {
  padding-right: 10%;
  padding-left: 0;
}

#checkoutsteps {
  ul {
    .number {
      align-items: center;
      background-color: #eaedf7;
      border-radius: 50%;
      color: #9197ab;
      display: flex;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      height: 40px;
      justify-content: center;
      margin: 15px auto;
      width: 40px;
    }

    margin-top: -45px;

    a.active {
      background-color: transparent;

      span.number {
        background-color: #007BFFFF;
        color: #FFFFFFFF
      }
    }
  }

}

//Checkout Ecommerce Css End*******

//product details Css start*****
.tab-content1 img {
  width: 60% !important;
}

.preview-thumbnail {
  justify-content: center;
}

//product details Css end*****

//Userlist Css start****
.search-userData {
  margin-top: 5px;
  font-size: 18px;
}

//Userlist Css end****

// Rating Css Start****

.rating-stars > :is(.MuiRating-root, .MuiBox-root) {
  justify-content: center;
  margin: 0 auto;
}

// Rating Css End****

//RangeSlider Css Start*****
.rangeslider-horizontal .rangeslider__handle:after {
  display: none;
}

//RangeSlider Css End*****

//ChartJS Css Start****

.chartjs-wrapper-demo.Chart canvas {
  height: 300px !important;
  width: 300px !important;
  margin: 0 auto;
}

//ChartJS Css End****


//File Manager Details Start*******
.slick-next:before,
.slick-prev:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 50px;
  box-shadow: #00000019;
  color: #fff;
  font-family: feather;
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  padding: 10px;
}

.slick-prev,
.slick-next {
  z-index: 9;
}

.slick-prev {
  inset-inline-start: 5px !important;
}

.slick-next {
  inset-inline-end: 25px !important;
}

//File Manager Details End*******

//Simple Map Css Start****


.Usa-Map {
  .rsm-svg {
    width: 62%;
    transform: scale(1.25);
  }
}

.Europe-Map {
  .rsm-svg {
    width: 45%;
    transform: scale(1.10);
  }
}

.World-map {
  .rsm-zoomable-group {
    width: 62%;
    transform: scale(1.25);
  }
}

.SouthAmerica {
  .rsm-zoomable-group {
    width: 62%;
    transform: scale(1.25);
  }
}

//Simple Map Css End*****

.horizontal .hor-scroll {
  overflow: scroll !important;
}

@media (max-width: 991.98px) {
  .horizontal .hor-scroll {
    position: relative !important;
    overflow: scroll !important;

    > div {
      position: relative !important;

      &:last-child {
        position: absolute !important;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .horizontal .hor-scroll {
    position: static !important;

    > div {
      position: static !important;

      &:last-child {
        position: absolute !important;
      }
    }
  }
}

// .CountrySelector .flag {
//     background: transparent no-repeat;
// }

// .CountrySelector {
//     display: flex;

//     .react-tel-input {
//         margin-right: 10px;
//     }

//     .react-tel-input .flag-dropdown {
//         background: #f6f6fb;
//         border: 1px solid $border;
//     }

//     .react-tel-input .form-control {
//         border: 1px solid $border;
//     }
// }

// .react-tel-input .country-list .country.highlight {
//     background: #f6f6fb !important;
// }


.slick-dots {
  bottom: 15px !important;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}

.slick-dots li button:before {
  font-size: 10px !important;
  color: #fff !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 15px !important;
}

#basicSlider {

  .slick-prev:before,
  .slick-next:before {
    content: none;
  }

  .slick-dots {
    display: none !important;
  }
}

.rangeslider__handle-label {
  inset-inline-start: 4px;
  line-height: 2;
  position: absolute;
}

.toast .btn-close {
  width: 0.3em !important;
  height: 0.3em !important;
}

.tabs-style-4 .panel-tabs {
  border-bottom: 0 !important;
}

.accordion-button:not(.collapsed) {
  color: #0162E8FF !important;
  background-color: #0162E819 !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.coloured-accordion {
  .accordion-button:not(.collapsed) {
    color: #fff !important;
    background-color: #0162E8FF !important;
  }
}

.rodal-dialog {
  padding: 0 !important;
  height: max-content !important;
}

.rti--container {
  border-color: #e7ebf5 !important;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  content: none !important;
}

.MuiFormControl-root {
  display: flex !important;
}

.react-datepicker {
  font-family: inherit !important;
  border: 1px solid #e7ebf5 !important;
}

.react-datepicker__header {
  background-color: #ECF0FAFF !important;
  border-bottom: 1px solid #e7ebf5 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: #e7ebf5 !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: #ECF0FAFF !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0162E8FF !important;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text,
.react-datepicker__year .react-datepicker__year-text {
  margin: 10px !important;
}

.react-datepicker__year-wrapper {
  max-width: 300px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #0162E8FF !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  border-top-color: #e7ebf5 !important;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 8px
}

.Select-sm__control--is-focused {
  box-shadow: 0 0 0 1px #0162E8FF !important;
}

.react-tel-input {
  width: fit-content !important;
}

.react-tel-input .form-control {
  height: 40px !important;
  width: 200px !important;
  border-radius: 3px 0 0 3px;
  border: 1px solid #EAE8F1FF !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: #ECF0FAFF !important;
  border-color: #e7ebf5 !important;
  margin: 0;
  border-radius: 0 !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid #EAE8F1FF !important;
  background-color: #ECF0FAFF !important;
}

.react-tel-input .flag {
  background-size: auto !important;
}

.react-tel-input .country-list .country:hover {
  background-color: #ECF0FAFF !important;
}

.basicsteps .btn.active {
  background-color: #0162E8FF;
  color: #fff;
}

.Stepperh3 .step3.active {
  background-color: #0162E8FF;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 2;
  color: #fff;
}

.Stepperh3 .step2 {
  background-color: #ECF0FAFF;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 2;
  color: #031b4e;
}

.Stepperh3 .title {
  margin-top: auto;
  margin-bottom: auto;
}

.sun-editor {
  border: 1px solid #EAE8F1FF;
}

.sun-editor .se-toolbar {
  outline: none;
  background: #FFFFFFFF;
  z-index: 0;
}

.sun-editor .se-btn-module-border {
  border: 1px solid #EAE8F1FF;
}

.sun-editor .se-resizing-bar {
  background: #FFFFFFFF;
  border-top: 1px solid #EAE8F1FF;
}

.sun-editor .se-btn:enabled.active:focus,
.sun-editor .se-btn:enabled.active:hover,
.sun-editor .se-btn:enabled:focus,
.sun-editor .se-btn:enabled:hover {
  background-color: #ECF0FAFF;
  border-color: #EAE8F1FF;
}

.sun-editor .se-btn:enabled.active {
  color: #031b4e;
}

.RichTextEditor__root___2QXK- {
  border: 1px solid #EAE8F1FF !important;
}

.EditorToolbar__root___3_Aqz {
  border-bottom: 1px solid #EAE8F1FF !important;
}

.Button__root___1gz0c {
  border: 1px solid #EAE8F1FF !important;
  background: #ECF0FAFF !important;
  color: #031b4e !important;
}

.IconButton__isActive___2Ey8p {
  background: #00000033 !important;
}

.Dropdown__root___3ALmx .Dropdown__value___34Py9 {
  border: 1px solid #EAE8F1FF !important;
}

.Dropdown__root___3ALmx select {
  outline: none !important;
}

.ButtonGroup__root___3lEAn > div + div > button {
  border-left-width: 0 !important;
}

.ButtonWrap__root___1EO_R {
  z-index: 1 !important;
}

#delete-datatable input {
  background: transparent;
  border: 1px solid #EAE8F1FF;
  padding: 5px 10px;
}

.rating-table.table > :not(caption) > * > * {
  border-bottom: 0 !important;
}

.avatar-status-new {
  position: relative;
  margin-top: 30px;
}

.leaflet-container {
  z-index: 2;
}

.World-map .rsm-zoomable-group {
  transform: scale(1);
}

.World-map .controls {
  display: flex;
}

.demo-gallery .masonry img {
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.todocheck .ckbox input[type=checkbox] {
  opacity: 1;
  margin-inline-start: -25px;
}

.card-pay ul li {
  display: grid !important;
}

.card-pay .nav-tabs .nav-item .nav-link {
  margin-inline-start: 0;
  font-size: 13px;
  background: transparent;
  color: #031b4e;
}

.card-pay .nav-tabs .nav-item .nav-link.active {
  border-color: #007BFFFF;
  background-color: #007BFFFF !important;
  color: #FFFFFFFF;
}

@media (max-width: 575px) {
  #checkoutsteps ul .number {
    height: 25px;
    width: 25px;
  }

  .wd25p {
    padding-left: inherit;
    padding-right: 4%;
  }

  #checkoutsteps .nav-pills .nav-item {
    margin-top: 6px;
  }

  .pulse-danger:before {
    top: -3px;
  }

  .pulse-danger {
    top: -3px;
  }
}

@media (min-width: 576px) and (max-width: 1399px) {
  .wd25p {
    padding-left: inherit;
    padding-right: 5%;
  }
}

.MuiTreeItem-content.Mui-selected {
  //background-color: $primary-2  !important;
}

.collapsabletreeview {
  div ul li {
    margin: 0;
    padding: 0em 1em 0em 0em;
    line-height: 2em;
    color: #9eabc0;
    position: relative;

    :first-child {
      margin-bottom: 5px;
    }
  }
}

.collapsabletreeview1 {
  ul li {
    margin: 0;
    padding: 0em 1em 0em 0em;
    line-height: 2em;
    color: #9eabc0;
    position: relative;

    :first-child {
      margin-bottom: 5px;
    }
  }
}

.dark-theme .dTree svg,
.transparent-theme .dTree svg {
  filter: invert(1);
}

.fc.fc-media-screen .fc-button-primary,
.fc.fc-media-screen .fc-button-primary:hover {
  background-color: #007BFFFF;
  border-color: #007BFFFF;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: #007BFFFF !important;
  border-color: #007BFFFF !important;
  opacity: 0.9;
}

.fc.fc-media-screen .fc-button-primary:disabled {
  background-color: #007BFFFF !important;
  border-color: #007BFFFF !important;
  opacity: 0.5;
}

.fc-theme-standard td,
.fc-theme-standard th,
.fc-theme-standard .fc-scrollgrid {
  border-color: #EAE8F1FF !important;
}

.list-calender .fc.fc-media-screen .fc-view-harness {
  height: 270px !important;
}

.dark-theme .fc-theme-standard td,
.dark-theme .fc-theme-standard th,
.dark-theme .fc-theme-standard .fc-scrollgrid {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.transparent-theme .fc-theme-standard td,
.transparent-theme .fc-theme-standard th,
.transparent-theme .fc-theme-standard .fc-scrollgrid {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.dropzone {
  background-color: transparent;
  border: 2px dashed #EAE8F1FF;
  border-radius: 5px;
  color: #c3cddf;
  height: 250px;
  margin-bottom: 20px;
  padding: 17px;
  text-align: center;
}

.dropzone-main {
  bottom: 30px;
  left: 30px;
  position: absolute;
  right: 0;
}

.MuiDropzoneArea-root {
  border: 2px dashed #EAE8F1FF !important;
}

.MuiGrid-container {
  justify-content: center;
}

.MuiGrid-container .MuiGrid-item {
  margin: 5px;
}

.styled-treeview .MuiTreeItem-label {
  line-height: 2.5 !important;
}


.dark-theme {
  .apexcharts-tooltip-title {
    background: #141b2d !important;
  }

  .apexcharts-legend,
  .apexcharts-legend-text {
    color: #FFFFFFFF !important;
  }

  .apexcharts-menu,
  .apexcharts-menu-item {
    background: #141b2d !important;
    border: 1px solid #EAEDF133;
  }
}

.transparent-theme {

  .apexcharts-legend,
  .apexcharts-legend-text {
    color: #FFFFFFFF !important;
  }


  .tabs-style-4 .nav.panel-tabs .nav-item a,
  .tabs-menu .panel-tabs .nav-item .nav-link {
    background-color: rgba(0, 0, 0, 0.2);
    color: #FFFFFFFF;
  }

  .apexcharts-menu,
  .apexcharts-menu-item {
    background: #0b5bc9 !important;
    //border: 1px solid $transparent-border;
  }
}

.dark-theme {
  .MuiSlider-markLabel {
    color: #FFFFFFFF !important;
  }

  //.rangeslider {
  //    background: $dark-theme  !important;
  //}
}

.rtl {
  .side-menu__item.active {
    .angle {
      transform: rotate(90deg);
    }
  }

  .side-menu__item {
    .angle {
      transform: rotate(180deg);
    }
  }

  .slide-item {
    .sub-angle {
      transform: rotate(180deg);
    }
  }

  .fc-direction-ltr .fc-button-group > .fc-button:not(:first-child),
  .fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .fc-direction-ltr .fc-button-group > .fc-button:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 0;
    border-top-right-radius: 5px !important;
    margin-left: inherit;
    margin-right: -1px;
  }

  .fc-direction-ltr .fc-button-group > .fc-button:last-child {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 0;
  }

  .fc-icon-chevron-right {
    transform: rotate(180deg);
  }

  .fc-icon-chevron-left {
    transform: rotate(180deg);
  }

  .chat-profile-dropdown {
    left: 0;
    right: inherit;
  }

  .fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
    margin-right: 0.75em;
    margin-left: 0;
  }

  .alert-dismissible {
    padding-left: 3.8125rem;
  }

  .fc-direction-ltr {
    direction: rtl !important;
    text-align: right !important;
  }

  .toast-header .btn-close {
    margin-left: calc(-.5 * var(--bs-toast-padding-x));
    margin-right: var(--bs-toast-padding-x);
  }

  .apexcharts-legend-marker {
    margin-left: 3px;
  }

  .product-timeline ul li {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .MuiTreeItem-label {
    padding-right: 4px;
  }

  .MuiSvgIcon-root {
    margin-left: 8px;
  }

  .MuiCollapse-root {
    margin-right: 17px;
    margin-left: 0;
  }

  .page-item.active .page-link {
    margin-left: 4px;
    margin-right: 0;
  }
}

.dark-theme {
  #checkoutsteps ul .number {
    //background-color: $dark-body;
  }

  .data-table-extensions > .data-table-extensions-filter > .filter-text {
    border-bottom: 1px solid #EAEDF133;
  }

  .accordion-body {
    background: #1F2940FF;
    color: #FFFFFFFF;
  }

  .accordion-header::after {
    //background: $dark-theme;
    color: #FFFFFFFF;
  }
}

.timer {
  font-size: 25px;
}

.ReactModal__Overlay {
  z-index: 9999 !important;
}

path {
  pointer-events: all;
}

path:hover {
  opacity: 0.50;
  cursor: pointer;
}

@media (max-width: 574.98px) {
  .us-state-map {
    width: 100% !important;
    height: 100% !important;
  }
}

.map-selected {
  fill: #d1e4bb;
}

// .map-unselected {
//     fill: #699EAA;
// }

.map-selected:hover,
.map-unselected:hover {
  cursor: pointer;
  fill: #ffeb3b;
}

.demo-gallery .masonry img {
  margin: 4px 0 4px 0;
}

@media (max-width: 767.98px) {
  .chartjs-wrapper-demo.Chart canvas {
    height: 200px !important;
    width: 200px !important;
    margin: 0 auto;
  }
}

@media (max-width: 991.98px) {
  .fc .fc-toolbar {
    display: block !important;
  }
}

.dark-theme {

  .tabs-style-4 .nav.panel-tabs .nav-item a,
  .tabs-menu .panel-tabs .nav-item .nav-link {
    background: #141B2DFF;
    color: #FFFFFFFF;
  }

  .main-content-contacts {
    .nav-tabs .nav-item .nav-link {
      background-color: transparent;
      color: #FFFFFFFF;
    }
  }

  .contact-icon:hover {
    background: #141b2d;
    color: #007BFFFF;
  }

  .success-widget,
  .danger-widget,
  .warning-widget {
    h3 {

      &:before,
      &:after {
        background: #EAEDF133;
      }
    }
  }

  .accordion-dark .accordion-button:not(.collapsed) {
    background: #1f2940 !important;
    color: #fff !important;
  }

  .accordion-item {
    border: 1px solid #EAEDF133 !important;
  }
}

.swiper.swiper-cards {
  padding: 20px;
}

.dark-theme {
  .rti--container {
    background-color: #141B2DFF !important;
    border: 1px solid #EAEDF133 !important;
  }

  .rti--input {
    background-color: #141B2DFF !important;
    color: #FFFFFFFF !important;
  }

  .css-1okebmr-indicatorSeparator {
    background-color: #EAEDF133;
  }

  .chat-profile {
    color: #fff;
  }
}

.transparent-theme {
  .nav-tabs .nav-item .nav-link {
    background-color: transparent;
    color: #FFFFFFFF;
  }

  .rti--container,
  .rti--input {
    background: transparent !important;
    color: #FFFFFFFF !important;
  }

  .rti--container {
    border: 1px solid rgba(255, 255, 255, 0.2) !important;
  }

  .rti--input {
    min-width: 20px;
    border: 0;
  }

  .Usa-Map {
    svg g text {
      fill: #FFFFFFFF !important;
      font-size: larger !important;
    }

    .rsm-geography {
      fill: #5a6970 !important;
    }
  }

  .MuiSlider-markLabel,
  .MuiSlider-valueLabelLabel {
    color: #FFFFFFFF !important;
  }
}

[dir='rtl'] {
  .slick-prev {
    right: 25px !important;
    left: auto !important;
  }

  .slick-next {
    right: auto !important;
    left: 5px !important;
  }

  .swiper-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0) !important;
  }
}

.rti--container:focus-within {
  box-shadow: none !important;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}

.transparent-theme .main-header.sticky.sticky-pin,
.transparent-theme .horizontal-main {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.mail-setting {
  margin-right: 20px !important;
}

.chat-profile-dropdown {
  position: absolute;
  right: 0;
  top: 0;
}

.card--events .list-group-item.active h6 {
  color: #fff;
}

.rtl {
  .profile-menu {
    right: auto !important;
    left: 0 !important;
    transform: unset !important;
  }
}

.dark-theme {
  .btn-light-gradient {
    color: #000;

  }

  .btn-light-gradient:hover {
    color: #000;
    background: linear-gradient(120deg, #c1c7d6 0%, #e0e5f1 100%);
    border-color: #cdd2de;
  }
}

.rtl {
  .accordion-button::after {
    margin-right: auto !important;
    margin-left: inherit !important;
  }
}

@media (max-width: 410px) {
  .rodal-dialog {
    width: 80% !important;
    height: 50% !important;
  }
}

.dark-theme.Usa-Map svg g text {
  fill: #FFFFFFFF !important;
}

.dark-theme {
  .MuiRating-icon.MuiRating-iconEmpty {
    color: #FFFFFFFF !important;
  }

  .Usa-Map {
    svg g text {
      fill: #FFFFFFFF !important;
      font-size: larger !important;
    }

    .rsm-geography {
      fill: #5a6970 !important;
    }
  }
}

.rtl {
  .pagination {
    padding-right: inherit !important;
    padding-left: 0 !important;
  }
}

.hor-scroll > div:nth-child(3) > div {
  background-color: transparent !important;
}

.settings-main-icon i {
  background-color: #007BFFFF !important;
  color: #FFFFFFFF !important;
}

.dark-theme {
  .settings-main-icon i {
    background-color: #007BFFFF !important;
  }

  .MuiSlider-valueLabelLabel {
    color: #FFFFFFFF !important;
  }
}

@media (max-width: 991px) {
  .transparent-theme .horizontalMenucontainer .main-header {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
}

@media (min-width: 991px) {
  .transparent-theme .horizontalMenucontainer .main-header {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
  }
}

.apexcharts-reset-icon {
  z-index: 9999;
}

.apexcharts-toolbar {
  z-index: 0 !important;
}

.transparent-theme .main-sidebar-header {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

.data-table-extensions > .data-table-extensions-action {
  display: none;
}


////////////////////////////

.us-state-map {
  width: 100%;
}

.main-table-reference.table {
  td:first-child {
    background-color: #ecf0fa;
  }
}

.dark-theme {
  .main-table-reference.table {
    td:first-child {
      background-color: #141b2d;
    }
  }

  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
    color: #fff !important;
  }

  @media (min-width: 768px) {
    .main-nav .nav-link + .nav-link {
      border-top: 0;
    }
  }

  .accordion-item {
    background-color: transparent;
  }

  .timeline-wrapper-dark .timeline-panel:before,
  .timeline-wrapper-dark .timeline-badge {
    background: rgba(255, 255, 255, 0.2);
  }

  .Select-sm__menu {
    .Select-sm__option:hover {
      background-color: #007BFFFF !important;
    }

    .Select-sm__option {
      background-color: #141b2d !important;
    }
  }
}

.ril__navButtons {
  background-color: #0162e8;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  padding: 25px;
  opacity: 1;
}

.ril-toolbar {
  background-color: #0162e8;
}

.transparent-theme {
  .list-group-item + .list-group-item {
    border-top-width: 0;
  }

  .Select-sm__indicator-separator {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 1143px) {
    .rating-table.table tr td:last-child {
      border-left-color: transparent;
      border-right-color: inherit;
    }
  }

  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
    color: #fff !important;
  }

  @media (min-width: 768px) {
    .main-nav .nav-link + .nav-link {
      border-top: 0;
    }
  }

  .accordion-item {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }

  .Select-sm__menu {
    .Select-sm__option:hover {
      background-color: #007BFFFF !important;
    }

    .Select-sm__option {
      background-color: #0B5BC9FF !important;
    }
  }
}

.horizontal .hor-scroll {
  overflow: hidden scroll !important;
}

@media (max-width: 991px) {
  body.rtl.horizontal.sidenav-toggled .app-sidebar.horizontal-main {
    left: auto;
    right: 0;
  }
}

.rtl {
  .breadcrumb-style2 .breadcrumb-item + .breadcrumb-item::before {
    transform: rotate(180deg);
  }

  .accordion-button {
    text-align: right;
  }

  .MuiInputBase-root {
    .MuiInputBase-input {
      text-align: right;
    }
  }

  .dropzone-main {
    right: 30px;
    left: 0;
  }
}

.rodal-mask {
  background: rgba(0, 0, 0, 0.8);
}

.rodal-dialog {
  z-index: 9999;
}

.rodal,
.rodal-mask {
  z-index: 9999;
}

.swal2-file:focus,
.swal2-input:focus,
.swal2-textarea:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(2) contrast(0.5);
}

.sun-editor .se-btn {
  font-size: 16px;
}

.btn-main-primary:focus,
.btn-main-primary.focus {
  box-shadow: 0 0 0 0.2rem var(--primary-3);
}

.btn:focus-visible {
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
  box-shadow: var(--primary-3);
}

.slick-initialized .slick-prev:before,
.slick-next:before {
  font-size: 30px !important;
}

.dark-theme {
  .multi-select .dropdown-content {
    background-color: #1F2940FF;

    div {
      background-color: #1F2940FF;

      label {
        &:hover, &.selected {
          background-color: #FFFFFF14;
        }
      }
    }
  }
}

.app.horizontal {
  .horizontal-content {
    min-height: calc(100vh - 50px);
  }
}

.dark-theme {
  .fc .fc-list-sticky .fc-list-day > * {
    background: var(--fc-page-bg-color, transparent);
  }
}

path {
  pointer-events: all;
}

path:hover {
  opacity: 0.5;
  cursor: pointer;
}

.swal2-container {
  z-index: 99999 !important;
}

.toggle-effect {
  cursor: pointer;
}
.toggle-effect:hover, .toggle-effect:active {
  opacity: .75;
}